aside {
    padding: 15px 20px;

    .nav-menu nav ul li {
        width: 250px;
        text-align: left;

        i {
            font-size: 22px;
            width: 24px;
            text-align: center;
            display: inline-block;
            margin-right: 12px;
            color: $color-primary;
            transition: $transition;
        }

        &:nth-last-child(2) { 
            margin-top: 12px;
            padding-top: 30px;
            border-top: 1px solid rgba($color-tertiary, .3);
        }
    }

    & + main section {
        padding: 20px 0;
    
        .container {
            margin: 0;
            width: 100%;
            max-width: 1000px;
        }
    
        & + section {
            padding-top: 0;
        }
    }

    @include media($lg) {
        position: fixed;
        top: 0;
        left: 0;
        width: $aside-width;
        height: 100%;
        padding: 20px;
    
        .nav-menu {
            nav {
                display: block;
                margin: 20px 0 0;
        
                ul li {
                    display: block;
                    text-align: left;
                    padding: 0;
                    width: auto;
        
                    a {
                        display: block;
                        padding: 6px 6px 6px 0;
        
                        &:hover i {
                            color: $color-secondary;
                        }
                    }

                    i {
                        font-size: 18px;
                        width: 20px;
                        position: relative;
                        top: 2px;
                    }
        
                    &:nth-last-child(2) {
                        padding-top: 12px;
                    }
                }
            }
        }
    
        & + main {
            padding-left: $aside-width;
        }  
    }
}

.section-title {
    .content {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: 1px solid rgba($color-tertiary, .3);
        padding-bottom: 20px;
    }

    h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 400;
        font-family: $font-text;
        color: rgba($color-text, .8);

        i {
            margin-right: 5px;
            color: $color-primary;
        }
    }
}

.tab-content {
    ul {
        border-color: rgba($color-tertiary, .3);
    }
    
    .tab {
        color: rgba($color-text, .5);
        font-weight: 400;

        &.active,
        &:hover {
            color: $color-text;
        }

        &.active {
            border-color: rgba($color-tertiary, .3);
            border-bottom-color: transparent;
        }

        &.has-error {
            color: $red;
        }
    }
}

#notificador {
    background: $color-primary;
    opacity: 1;
}

.dataTable {
    width: 100% !important;
    margin: 0 auto 10px !important;
    display: block;
    overflow: auto;

    th, td {
        border: 1px solid rgba($color-tertiary, .3);

        &:not(:last-child) {
            border-right: none !important;
        }
    }

    tr:not(:last-child) td {
        border-bottom: none;
    }

    thead {
        th, td {
            border-bottom: none !important;
        }
    }

    &.table-actions {
        th, td {

            &:last-child {
                padding: 4px;
                max-width: 40px;
            }

            .btn {
                text-align: center;
            }

            .fas {
                font-size: 10px;
                position: relative;
                bottom: 2px;
            }
        }
    }

    &.table-edit td:last-child {
        text-align: center;
        max-width: none;
        text-overflow: initial;

        a {
            padding: 8px;
            display: inline-block;
        }

        .fas {
            font-size: 22px;
        }

        .fa-trash-alt {
            color: $color-danger;
            transition: $transition;

            &:hover {
                color: $color-danger-hover;
            }
        }
    }

    .odd {
        background: $color-background;
    }

    &.no-footer {
        border-bottom: none !important;
    }

    &s_info {
        padding: 0 !important;
    }

    &s_length,
    &s_filter {
        margin-bottom: 15px;
    }

    &s_empty {
        padding: 4px 10px !important;
        text-align: left !important;
        border-top: 1px solid rgba($color-tertiary, .3);
    }

    .btn {
        border-radius: 5px;
        padding: 8px;
        width: 100%;
        height: auto;
    }

    @include media($md) {
        display: table;

        &.table-edit td:last-child {
            a {
                padding: 5px;
                display: inline;
            }
    
            .fas {
                font-size: 18px;
            }
        }
    }
}

.dataTables_wrapper {
    .dataTables_paginate .paginate_button {
        &:hover, &.current {
            background: $color-background !important;
            border-color: rgba($color-tertiary, .3) !important;
            color: $color-text !important;
        }
    }
}

#previewImage {
    border: 1px solid rgba($color-gray-light, .8);
    padding: 20px;
    max-width: 250px;
}

.border-bottom {
    border-bottom: 1px solid rgba($color-primary, .3);
    font-weight: 400;
    color: $color-primary;
}