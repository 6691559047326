.main-login {
    background: $color-background;

    .logo {
        width: 150px;
        margin: 0 auto 20px;
    }

    .section-login {
        width: 400px;
        max-width: 100%;
        padding: 20px;
        text-align: center;

        button {
            margin-top: 10px;
            padding: 12px 60px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            padding: 0 20px;

            &.message {
                margin-bottom: 25px;
                

                &.error {
                    color: $color-danger;
                }
            }

            &.bottom {
                font-size: 16px; 
            }
            
            &.forgot {
                margin-bottom: 6px;

                a {
                    font-size: 15px;
                    font-weight: 300;
                    color: $color-text;
                }
            }
        }

        form .message {
            margin-bottom: 5px;
        }

        a {
            font-weight: 500;
        }
    }
}