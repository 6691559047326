$breakpoint-table: $md;

.table-responsive {
    &--header {
        margin-bottom: 20px;
    }

    &--title {
        text-align: center;
        margin-bottom: 20px;
    }

    &--head {
        display: none;
        font-size: 14px;
        line-height: 22px;

        & > div {
            padding: 10px 20px;
            border-bottom: 1px solid $gray;
        }
    }

    &--item {
        padding-bottom: 20px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        & > div {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &[data-title] {
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: right;

                &:before {
                    content: attr(data-title)":";
                    display: inline-block;
                    margin-right: 5px;
                    text-align: left;
                }
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid rgba($gray, .5);
        }
    }

    @include media($breakpoint-table) {
        &--header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &--title {
            text-align: left;
            margin-bottom: 0;
        }

        &--content {
            width: 100%;
            display: table;
        }

        &--head {
            display: table-header-group;
        }

        &--body {
            display: table-row-group;
        }

        &--item {
            display: table-row;

            &:not(:last-child) > div {
                border-bottom: 1px solid rgba($gray, .5);
            }

            & > div {
                padding: 15px 20px;

                &[data-title] {
                    display: table-cell;
                    text-align: left;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        &--head > div,
        &--item > div {
            display: table-cell;
            vertical-align: middle;
        }
    }
}
