body {
	font-family: $font-text, sans-serif;
    font-size: 15px;
    line-height: 28px;
    font-weight: 300;
    background: $white;
    color: $color-text;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-title, sans-serif;
    font-weight: 400;
    letter-spacing: -0.2px;
    color: $color-primary;
}

a, button {
    color: $color-primary;
    cursor: pointer;
    transition: $transition;

    @include hover {
        &:hover {
            color: $color-primary-hover;
            text-decoration: none;
        }
    }
}

p strong, li strong {
    font-weight: 500;
}

i {
    font-style: normal;
}

.fa-facebook-f:hover { color: $facebook; }
.fa-twitter:hover { color: $twitter; }
.fa-linkedin-in:hover { color: $linkedin; }
.fa-instagram:hover { color: $instagram; }
.fa-pinterest-p:hover { color: $pinterest; }

.social-list li {
    display: inline-block;
    margin-right: 20px;
    font-size: 20px;

    .fa-facebook-f { color: $facebook; }
    .fa-twitter { color: $twitter; }
    .fa-linkedin-in { color: $linkedin; }
    .fa-instagram { color: $instagram; }
    .fa-pinterest-p { color: $pinterest; }

    @include hover {
        a:hover {
            opacity: .8;
        }
    }
}

.bg {
    &-primary {
        background-color: $color-primary;
    }

    &-soft {
        background-color: $color-background;
    }

    &-white {
        background-color: $white;
    }
}

.color {
    &-primary {
        color: $color-primary;
    }
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gb-loader {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        content: '';
        display: block;
        width: 60px;
        height: 60px;
        background: url(/images/isotipo.svg) center no-repeat;
        background-size: contain;
        animation: blinker 1s linear infinite;
    }
}

.hide {
    display: none;
}

.list {
    list-style: disc;
    margin-left: 20px;
}

.table-bordered {
    border-collapse: collapse;
    border: 1px solid $color-text;
    margin: 10px 0px;

    th, td {
        border: 1px solid $color-text;
        padding: 5px 10px;
    }
}

@keyframes blinker {
    20% { opacity: 1; }
    50% { opacity: 0; }
    80% { opacity: 1; }
}