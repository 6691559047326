.box-cookies {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 400px;
    max-width: calc(100% - 40px);
    background: $white;
    border: 1px solid rgba($color-primary, .6);
    padding: 20px;
    padding-left: 75px;

    img {
        max-width: 40px;
        margin-bottom: 10px;
        position: absolute;
        top: 20px;
        left: 20px;
    }

    p {
        line-height: 22px;
    }

    .btn {
        padding: 4px 16px;
        font-size: 13px;
        font-weight: 500;
    }
}