// claudia framework

@import '../lib/claudia/scss/reboot';
@import '../lib/claudia/scss/variables';
@import '../lib/claudia/scss/globals';
@import '../lib/claudia/scss/grid';
@import '../lib/claudia/scss/menu';
@import '../lib/claudia/scss/buttons';
@import '../lib/claudia/scss/forms';
@import '../lib/claudia/scss/tables';
@import '../lib/claudia/scss/tabs';
@import '../lib/claudia/scss/modals';

// icon libraries

@import '../lib/font-awesome/all';


// partials

@import 'partials/fonts';
@import 'partials/variables';
@import 'partials/guide';
@import 'partials/buttons';
@import 'partials/header';
@import 'partials/footer';
@import 'partials/panel';
@import 'partials/login';
@import 'partials/home';
@import 'partials/faqs';
@import 'partials/contact';
@import 'partials/forms';
@import 'partials/modals';
@import 'partials/print';
@import 'partials/cookies';
