$print-color: #222;
$print-border-table: #dbdbdb;
$print-background-thead: #f5f5f5;

.page-invoice,
.page-ticket {
    position: fixed;
    top: 0;
    left: -9999px;
    z-index: 1;
    background: #fff;
    word-break: break-word;
    color: $print-color;
}

.page-invoice {
    width: 860px;
    height: 1200px;
    padding: 50px;

    .logo {
        max-width: 200px;
        max-height: 60px;
        margin-bottom: 15px;
    }

    .info {
        margin-bottom: 30px;
    }

    .company {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 10px;
    }

    table {
        width: 100%;
        border: none;
        border-spacing: 0px;
        margin-bottom: 20px;

        th, td {
            padding: 0px 10px;
            border: 1px solid $print-border-table;
            border-right: none;
            border-bottom: none;
            height: 32px;
            line-height: 10px;
            vertical-align: top;

            &:last-child {
                border-right: 1px solid $print-border-table;
            }

            ul {
                line-height: 26px;
                margin-bottom: 20px;
            }
        }

        thead th {
            background: $print-background-thead;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
        }

        tbody {
            tr:last-child td {
                border-bottom: 1px solid $print-border-table;
            }
        }

        &.table-center td {
            text-align: center;
        }

        &.table-right,
        &.table-products {
            td {
                &:nth-last-child(1),
                &:nth-last-child(2),
                &:nth-last-child(3) {
                    text-align: right;
                }
            }
        }

        &.table-products {
            th, td {
                &:first-child {
                    width: 55%;
                }
            }
        }

        &.table-totals {
            td {
                text-align: right;
            }

            tbody tr:last-child td {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                height: 40px;

                span {
                    margin-right: 5px;
                }
            }
        }
    }

    .bottom {
        position: absolute;
        width: calc(100% - 100px);
        bottom: 50px;
        left: 50px;

        p {
            margin: 0;
        }
    }
}

.page-ticket {
    width: 400px;
    padding: 0 15px 10px;
    font-size: 16px;
    line-height: 22px;

    strong {
        font-weight: 700;
    }

    ul, p {
        margin: 0;
    }

    hr {
        position: relative;
        top: 11px;
    }

    .info {
        text-align: center;
    }

    .date {
        display: flex;
        justify-content: space-between;
    }

    table {
        width: 100%;
        border-spacing: 0;
        margin: 15px 0 10px;

        th, td {
            text-align: right;
            vertical-align: top;
            line-height: 14px;
            height: 24px;
            padding: 0;

            &:first-child {
                width: 50%;
                text-align: left;
            }
        }
    }

    .total {
        text-align: right;
        font-size: 18px;
    }

    .nota {
        text-align: center;
    }
}