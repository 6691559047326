header, aside {
    background: $color-background;
    padding: 15px 0;

    .logo {
        position: relative;
        right: 5px;
        display: flex;
        align-items: center;

        img {
            width: 142px;
            margin-right: 5px;
        }

        span {
            font-size: 13px;
            color: $color-text;
            font-family: $font-title;
            font-weight: 500;
            opacity: .8;
        }
    }

    .nav-menu nav ul li a {
        color: $color-text;
        font-weight: 400;

        &:hover {
            color: $color-primary;
        }

        &.btn {
            font-family: $font-text;
            color: $white;
            padding: 14px 40px;
            font-size: 22px;

            @include media($lg) {
                padding: 8px 30px;
                font-size: 15px;
            }
        }
    }
}