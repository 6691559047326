// color

$color-text:                   #324864;
$color-text-light:             #ffffff;
$color-gray-light:             #B7B7B7;
$color-gray:                   #8d8d8d;
$color-background:             #F6F9FF;

$color-primary:			       #3E78E3;
$color-primary-hover: 	       #3264c1;
$color-secondary: 		       #0232A0;
$color-secondary-hover:        #03287c;
$color-tertiary:               #90B1FF;
$color-tertiary-hover:         #90B1FF;

$color-danger:                 #d75a4a;
$color-danger-hover:           #b5483a;


// fonts

$font-text: 'Roboto';
$font-title: 'Raleway';
$font-logo: 'Dongle';
$font-icons: 'Font Awesome 5 Free';


// header
$header-height: 65px;
$aside-width: 240px;
