.modal {
    &-header {
        background: $color-background;
        font-family: $font-title;
        font-weight: 500;
        text-align: left;

        h5 {
            margin: 0;
            font-weight: 500;
        }
    }

    &-footer {
        border-color: rgba($color-tertiary, .3);

        .btn {
            font-weight: 500;
        }
    }

    &-danger {
        .modal-header {
            background: rgba($red, .1);
    
            h5 {
                color: $red;
            }
        }
    
        .modal-footer {
            border-color: rgba($red, .2);
        }
    }

    @include media($lg) {
        &-footer {
            justify-content: flex-end;
        }
    }
}