//
// Modals
//

.modal {
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background: rgba($black, .5);
	display: none;
	opacity: 0;
	transition: $transition;
	padding: 20px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	&-dialog {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		margin: 0 auto;
		max-width: 100%;
		min-height: 100%;
		transition: $transition;
		top: -50px;
		width: 500px;

		&.modal-lg {
			width: 750px;
		}
	
		&.modal-xl {
			width: 1000px;
		}
	}

	&-content {
		background: $white;
		box-shadow: 0px 0px 30px rgba($black,.2);
		width: 100%;

		p {
			line-height: 30px;
		}

		.icon {
			height: 70px;
			margin: 0 auto 20px;
		}

		button {
			margin: 20px 5px 0px;
		}
	}

	&-header,
	&-body, 
	&-footer {
		padding: 20px;
	}

	&-header {
		background: rgba($black, .05);
	}

	&-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
		border-top: 1px solid rgba($black, .1);
		padding: 15px;

		.btn {
			margin: 5px;
		}
	}

	&.show {
		display: block;
	}

	&.active {
		opacity: 1;
		
		.modal-dialog {
			opacity: 1;
			top: 0px;
		}
	}
}

[data-modal] {
	cursor: pointer;
}