footer {
    background: $color-background;
    padding: 30px 0;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    .row {
        align-items: center;

        & > div:last-child {
            font-weight: 500;
            margin-top: 10px;
        }
    }

    p a {
        font-weight: 400;
    }

    .social {
        margin: 10px 0 0 0;

        li {
            display: inline-block;
            font-size: 24px;
            margin: 0 5px;
        }
    }

    @include media($md) {
        text-align: left;

        .row > div:last-child {
            text-align: right;
            margin: 0;
        }

        .social {
            margin: 0;
            
            li {
                margin: 0 0 0 10px;
            }
        }
    }
}