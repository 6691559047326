//
// Menu
//

$break-menu: $lg;

@mixin break-menu($break-menu) {
    @include media($break-menu) {
		.toggle {
			display: none;
		}

		nav {
			position: static;
			width: auto;
			height: auto;
			background: transparent;
            align-items: flex-end;
            margin-right: -20px;

			ul {
				margin: 0px;

				li {
					display: inline-block;
                    padding: 10px 20px;

					a {
						font-size: 16px;
					}
				}
			}
		}
	}
}

.nav-menu {
    .toggle {
        position: relative;
        z-index: 12;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;

        p {
            display: inline-block;
            flex: 1;
            text-align: right;
            margin: 0px;
            margin-right: 15px;
            transition: $transition;
        }

        .burger {
            display: inline-block;

            span {
                display: block;
                position: relative;
                width: 30px;
                height: 2px;
                background: $dark;
                margin: 6px 0px;
                transition: $transition;
            }
        }
    }

    nav {
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100vh;
        top: -100vh;
        left: 0px;
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba($white,.98);
        list-style: none;
        transition: $transition;

        ul {
            margin-bottom: 50px;

            li {
                padding: 20px;
                text-align: center;

                a {
                    font-size: 24px;
                    padding: 0px;
                    color: $dark;

                    &:hover {
                        color: $black;
                    }
                }
            }
        }
	}
	@if $break-menu {
		@include break-menu($break-menu);
	}
}

body.menu {
    overflow: hidden;

    .nav-menu {
        .logo {
            opacity: 0;
        }

        .toggle {
            p {
                opacity: 0;
            }

            span {
                background: $black;

                &:nth-child(1) {
                    transform: rotate(45deg);
                    top: 8px;
                }

                &:nth-child(2) {
                    transform: rotate(-45deg);
                }

                &:nth-child(3) {
                    opacity: 0;
                }
            }
        }

        nav {
            top: 0px;
        }
    }
}