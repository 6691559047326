.main-home {
    h3 {
        font-size: 28px;
        line-height: 36px;
    }

    .btn {
        font-size: 16px;
        padding: 12px 45px;
    }

    .show-lg,
    .show-xl {
        display: none;
    }

    .box-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .section-hero {
        background: $color-background;

        .wrapper {
            display: flex;

            & > div {
                &:first-child {
                    max-width: 495px;
                }

                &:last-child {
                    position: relative;
                    flex: 1;
                
                    img {
                        max-width: none;
                        width: 650px;
                        position: absolute;
                        left: 60px;
                        top: -70px;
                    }
                }
            }
        }

        h1 {
            font-size: 36px;
            line-height: 44px;
            font-weight: 300;
            margin-bottom: 30px;
        }

        .btn {
            font-size: 17px;
            margin-top: 10px;
        }
    }

    .section-features {
        .row-text {
            margin-bottom: 50px;
        }

        .row-features {
            text-align: center;
            margin-bottom: 50px;
            gap: 30px 0;

            & > div:nth-child(1) .icon img {
                position: relative;
                top: -2px;
            }

            & > div:nth-child(3) .icon img {
                position: relative;
                top: 2px;
                left: 2px;
            }

            .icon {
                background: rgba(#CEDDFA, .5);
                width: 68px;
                height: 68px;
                margin: 0 auto 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
            }

            h4 {
                font-family: $font-text;
                font-size: 18px;
                font-weight: 400;
                color: $color-primary;
                margin-bottom: 10px;
            }

            p {
                font-size: 14px;
                line-height: 26px;
            }
        }

        .row-cloud {
            p {
                margin-bottom: 25px;
            }

            ul {
                margin: 0;
            }

            li {
                position: relative;
                padding-left: 40px;
                margin-bottom: 20px;
                text-align: left;

                &:last-child {
                    margin-bottom: 0px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 0px;
                    width: 24px;
                    height: 24px;
                    background: url(/images/icons/check.svg);
                }

                strong {
                    color: $color-primary;
                    font-weight: 500;
                }
            }

            .image {
                width: 250px;
            }
        }
    }

    .section-faqs {
        background: url(/images/background.jpg) left center;
        background-size: cover;
        text-align: center;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(73.72% 119.24% at 48.75% 41.1%, rgba(2, 50, 160, 0.345) 0%, rgba(134, 171, 252, 0) 100%)
        }

        .container {
            position: relative;
        }

        h3 {
            color: $white;
            line-height: 46px;
            font-weight: 300;
            max-width: 600px;
            margin: 0 auto 30px;
        }
    }

    .section-help {
        text-align: center;

        .row > div:first-child {
            position: relative;
        }

        .box-icon img {
            width: 70px;
        }

        p {
            max-width: 450px;
            margin: 0 auto 20px;
        }
    }

    @include media($sm) {
        .section-features {
            .row-features,
            .row-text {
                margin-bottom: 6vw;
            }
        }
    }

    @include media($md) {
        .section-features .row-text h3 {
            margin-bottom: 0px;
            padding-right: 50px;
        }
    }

    @include media($lg) {
        h3 {
            font-size: 32px;
            line-height: 40px;
        }

        .show-lg {
            display: block;
        }

        .box-icon {
            position: absolute;
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            background: $white;
            box-shadow: 0px 0px 80px rgba(33, 60, 111, 0.1);
            border-radius: 20px;
        }

        .section-hero h1 {
            font-size: 46px;
            line-height: 54px;
        }

        .section-features .row-cloud {
            & > div:last-child {
                display: flex;
                justify-content: flex-end;
            }
        }

        .section-help {
            text-align: left;

            .row > div {
                position: relative;

                &:last-child {
                    padding-left: 60px;
                }
            }

            .box-icon {
                top: -20px;
                right: -20px;

                img {
                    width: auto;
                }
            }

            p {
                max-width: 330px;
                margin: 0 0 20px;
            }
        }
    }

    @include media($xl) {
        .show-xl {
            display: block;
        }

        .box-icon {
            width: 144px;
            height: 144px;
            padding: 15px;
        }

        .section-features .row-cloud {
            & > div {
                position: relative;
            }

            .box-icon {
                left: 50px;
                top: 40px;
            }

            .image {
                width: 350px;
            }
        }

        .section-help {
            .row > div:nth-child(2) {
                margin-top: 40px;
            }

            .box-icon {
                right: -46px;
                top: -60px;
            }
        }
    }
}