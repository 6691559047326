.form-control {
    border: 1px solid rgba($color-gray-light, .8);
    height: 50px;

    &:focus {
        border-color: $color-secondary;
        box-shadow: none;
    }

    &::placeholder {
        color: rgba($color-text, .4);
    }

    &[disabled] {
        background: $color-background;
        border-color: rgba($color-gray-light, .5);
    }

    &.has-error {
        border-color: $color-danger;
    }
}

.dataTable.has-error {
    border: 1px solid $color-danger !important;
}

.form-group {
    .checkbox label:before {
        border-color: $color-primary;
    }

    .message {
        position: relative;
        top: 5px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: $color-danger;

        &:before {
            content: '*';
            display: inline-block;
            margin-right: 5px;
        }
    }
}

.form-group label.required:after,
.label-required {
    color: $color-primary;
    font-weight: 800;
}

.form-success {
    color: $color-primary;
    margin: 15px 0 0;
}

.toggle-pass {
    position: relative;
    
    input {
        padding-right: 55px;
    }

    i {
        cursor: pointer;
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        color: $color-primary;
        font-size: 18px;
        line-height: 50px;
        padding: 0 20px;

        &.fa-eye-slash {
            right: -1px;
        }
    }
}

select {
    background: url(images/angle-down.svg) no-repeat;
    background-size: 10px;
    background-position: calc(100% - 15px) center;
}

.dataTables_wrapper {
    select, input {
        -webkit-appearance: none;
		appearance: none;
        height: 45px;
        padding: 10px 15px;
    }

    .dataTables_length select {
        width: auto;
        margin-bottom: 10px;
        padding-right: 35px;
        margin: 0 5px;
    }
}