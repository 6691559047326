.tab-content {
    ul {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        border-bottom: 1px solid $gray;
    }

    .tab {
        color: $gray;
        display: inline-block;
        padding: 10px 20px;
        border: 1px solid transparent;
        margin: 0 0 -1px -1px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        user-select: none;

        &.active,
        &:hover {
            color: $gray-dark;
        }

        &.active {
            border-color: $gray;
            border-bottom-color: transparent;
            background: $white;
        }
    }

    .content {
        display: none;
        opacity: 0;
        transition: $transition;

        &.show {
            display: block;
        }

        &.active {
            opacity: 1;
        }
    }
}