//
// Color system
//

$blue:      		 #007bff;
$sea:      		 	 #0063ce;
$indigo:    		 #6610f2;
$purple:   			 #6f42c1;
$pink:       		 #e83e8c;
$red:        		 #d75a4a;
$orange:     		 #fd7e14;
$yellow:     		 #ffc107;
$green:      		 #28a745;
$teal:       		 #25b899;
$cyan:       		 #17a2b8;

$white:      		 #ffffff;
$gray-light: 		 #dee2e6;
$gray:       		 #adb5bd;
$gray-dark:  		 #495057;
$black:      		 #000000;

$facebook: 			 #3b5998;
$twitter: 			 #00aced;
$linkedin: 			 #007bb6;
$instagram: 		 #b84291;
$pinterest: 		 #cb2027;
$youtube: 			 #ff0001;
$twitch:			 #9146ff;
$whatsapp:           #46c554;	
$telegram: 		     #37a5f0;

$success:    		   $green;
$info:       		   $cyan;
$warning:     		   $yellow;
$danger:      		   $red;
$light:        		   $gray-light;
$dark:         		   $gray-dark;


//
// Fonts
//

$font-size: 16px;
$line-height: 32px;
$font-weight: 500;
$letter-spacing: 0px;


//
// Grid breakpoints
//

$sm: 576;
$md: 768;
$lg: 992;
$xl: 1200;

$breakpoints: (
	'sm': $sm,
	'md': $md,
	'lg': $lg,
	'xl': $xl
);

$grid-system: 12;
$col-spaccing: 20;


//
// Others
//

$transition: .3s all ease;