.section-faqs {
	.text {
		align-items: center;

		& > div:last-child {
			display: none;

			@media (min-width: 768px) {
				display: block;

				img {
					max-width: 350px;
                    margin: 0 auto;
				}
			}
		}

		a {
			font-weight: 600;
		}
	}

	.faqs {
		margin-top: 30px;

		@media (min-width: 768px) {
			margin-top: 30px;
		}

		.col:last-child {
			margin-top: 30px;

			@media (min-width: 768px) {
				margin-top: 0px;
			}
		}
	}

	h4 {
		color: $color-secondary;
		font-size: 18px;
		margin: 0px 0px 15px;
	}

	& + section .btn-solid {
        margin-top: 5px;
    }
}

.accordion {
    margin-top: 50px;

    .item {
        margin-top: 8px;

        &.active {
            .title:after {
                transform: rotate(180deg);
            }
    
            .content {
                display: block;
            }
        }
    }

	.title {
        background: $color-background;
		position: relative;
		width: 100%;
		border-radius: 0px;
		text-align: left;
		padding: 15px 40px 15px 15px;
		font-weight: 500;
		cursor: pointer;

		&:after {
			content: '';
			position: absolute;
			top: calc(50% - 7px);
			right: 20px;
			width: 14px;
			height: 14px;
			background: url(/images/icons/arrow.png) center no-repeat;
			background-size: contain;
		}
	}

	.content {
        background: $white;
		padding: 20px;
		font-size: 15px;
		line-height: 22px;
        display: none;

		p {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0px;
			}
		}

		a {
			font-weight: 600;
		}
	}
}

