.btn {
    background: $color-primary;
	color: $white;
	border-radius: 50px;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: 0.4px;
	font-family: $font-title;

	&:hover, &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
	
	&:hover {
		background: $color-primary-hover;
		color: $white;
	}

	&-blue-dark {
		background: $color-secondary;

		&:hover {
			background: $color-secondary-hover;
		}
	}

	&-black {
		background: #333;

		&:hover {
			background: #111;
		}
	}

	&-gray {
		background: rgba($color-gray, .6);

		&:hover {
			background: rgba($color-gray, .8);
		}
	}

	&-danger {
		background: $color-danger;

		&:hover {
			background: $color-danger-hover;
		}
	}

	&-success {
		position: relative;
		pointer-events: none;
		overflow: hidden;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $green;
		}

		&:before {
			content: '\f00c';
			font-family: $font-icons;
			font-size: 20px;
    		line-height: 20px;
			position: absolute;
			z-index: 1;
			width: 20px;
			height: 20px;
			top: -20px;
			left: calc(50% - 10px);
			animation: success .5s .5s ease forwards;
		}
	}
}

@keyframes success {
    0% { top: -20px }
    100% { top: calc(50% - 10px); }
}