//
// Grid system
//

.container {
    width: 100%;
    padding: 0px $col-spaccing + px;
    margin: 0 auto;

    @each $media, $width in $breakpoints {
        @media (min-width: #{$width}px){
            width: $width - ($col-spaccing * 2) + px;
        }
    }

    &.container-md {
        max-width: 800px;
    }
    
    &.container-xs {
        max-width: 500px;
    }
}

.container-fluid {
    width: 100%;
    padding: 0px $col-spaccing + px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -($col-spaccing / 2) + px;
    margin-right: -($col-spaccing / 2) + px;

    &-center {
        align-items: center;
    }
}

[class*='col-'] {
    width: 100%;
    padding-left: ($col-spaccing / 2) + px;
    padding-right: ($col-spaccing / 2) + px;
}

@for $i from 1 through $grid-system {
    .col-#{$i} {
        width: calc(100% / #{$grid-system / $i});
    }
}

@each $media, $width in $breakpoints {
    @media (min-width: #{$width}px){
        @for $i from 1 through $grid-system {
            .col-#{$media}-#{$i} {
                width: calc(100% / #{$grid-system / $i});
            }
        }
    }
}

@mixin media($breakpoint, $side: 'min') {
    @media (#{$side}-width: #{$breakpoint}px) {
        @content;
    }
}

@mixin hover {
    @media (hover: hover) {
        @content;
    }
}