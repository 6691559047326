.section-contact {
	.container {
		@media (min-width: 992px) {
			display: flex;
			align-items: center;
		}

		.content {
			ul {
				margin-top: 30px;

				li {
					margin-bottom: 10px;
                    display: flex;
                    align-items: center;

					&:last-child {
						margin-bottom: 0px;
					}

					a {
						font-weight: 700;
					}

					img {
						width: 36px;
						margin-right: 10px;
					}
				}
			}
		}

		form {
			margin: 50px -30px 0px;
			padding: 50px 30px 0px;
			border-top: 1px solid rgba($color-primary, .3);
			text-align: center;

			@media (min-width: 576px) {
				margin: 30px 0px 0px;
				padding: 30px;
				border: none;
				background: rgba($color-primary, .05);
			}

			@media (min-width: 992px) {
				margin: 0px 0px 0px 50px;
				min-width: 400px;
				text-align: left;
			}

			@media (min-width: 1200px) {
				min-width: 500px;
			}

			label {
				display: inline-block;
			}
		}
	}
}
