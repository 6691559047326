//
// Buttons
//

.btn {
	display: inline-block;
	padding: 8px 40px;
	font-size: 16px;
	line-height: 24px;
	background: $blue;
	color: $white;
	transition: $transition;
	border: none;
	cursor: pointer;
	
	&:hover {
		background: $sea;
		color: $white;
	}
}