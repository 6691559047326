//
// Globals
//

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
    letter-spacing: $letter-spacing;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
    overflow: hidden;
}

section {
    padding: 50px 0px;

    @media (min-width: 768px) {
        padding: 6vw 0px;
    }
}

img, video, iframe {
    display: block;
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    transition: $transition;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    margin: 0px 0px 15px;
}

h1 {
    font-size: 3em;
    line-height: 1.2em;
}

h2 {
    font-size: 2.2em;
    line-height: 1.2em;
}

h3 {
    font-size: 2em;
    line-height: 1.2em;
}

h4 {
    font-size: 1.5em;
    line-height: 1.5em;
}

h5 {
    font-size: 1.2em;
    line-height: 1.6em;
}

h6 {
    font-size: 1em;
    line-height: 1.6em;
}

ul {
    margin: 0px 0px 10px;
    padding: 0px;
    list-style: none;
}

ol {
    margin: 0px 0px 10px;
    padding: 0px;
    padding-left: 20px;

    li {
        padding-left: 5px;
    }
}

button {
    background: transparent;
    border: none;
    box-shadow: none;
}

p {
    margin-bottom: 15px;
    
    &:last-child {
        margin-bottom: 0px;
    }
}

.hidden {
    display: none !important;
}

.disabled {
    pointer-events: none;
}

.text-center {
    text-align: center;
}

.mg-none {
    margin: 0 !important;
}

.mt {
    &-1 { margin-top: 5px; }
    &-2 { margin-top: 10px; }
    &-3 { margin-top: 15px; }
}

.trigger-click {
	cursor: pointer;
}

:focus {
    outline: none;
}