//
// Forms
//

.form-group {
    margin-bottom: 20px;

    &:disabled, &[disabled] {
        opacity: 1;
    }

	label {
		display: block;
		text-align: left;

		&.required:after {
			content: '*';
			display: inline-block;
			margin-left: 2px;
			color: $red;
		}
	}

	&.flex-group {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0px -5px;

		& > * {
			margin: 0px 5px;
		}
	}

	&.checkbox {
		input {
			display: none;

			&:checked + label:after {
				opacity: 1;
			}
		}

		label {
			position: relative;
			padding-left: 35px;
			line-height: 24px;

			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				top: 2px;
				left: 0px;
				border: 1px solid $blue;
				width: 20px;
				height: 20px;
				cursor: pointer;
			}

			&:after {
				content: '';
				position: absolute;
				z-index: 2;
				top: 6px;
				left: 4px;
				width: 12px;
				height: 12px;
				background: $blue;
				opacity: 0;
				transition: $transition;
			}

			& ~ label {
				margin-top: 10px;
			}
		}
	}
}

.form-control {
	-webkit-appearance: none;
	appearance: none;
	display: block;
	width: 100%;
	height: 45px;
	padding: 10px 15px;
	box-shadow: none;
	border: 1px solid $gray;
	border-radius: 0px;
	box-shadow: none;
}

textarea.form-control {
	height: auto;
	resize: none;
}

.field-text {
	text-align: left;
	margin: 10px 0px 0px;
	font-size: 14px;
    line-height: 18px;
    
    &.upper {
        margin: -10px 0px 10px;
    }
}

@keyframes shake {
    0% { left: 0px; }
    25% { left: -4px; }
    60% { left: 6px; }
    80% { left: -2px; }
    100% { left: 0px; }
}